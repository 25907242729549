import React from 'react';

import { RouteComponentProps } from '@reach/router';

import { ErrorPage404 } from '../Pages/ServerError/404';

const NotFound: React.FC<RouteComponentProps> = () => {
  return <ErrorPage404 />;
};

export default NotFound;
