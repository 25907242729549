export type CreditCardCode =
  | 'es'
  | 'ezs'
  | 'sms'
  | 'pfc'
  | 'pef'
  | 'vis'
  | 'eca'
  | 'amx'
  | 'din'
  | 'dd'
  | 'pex'
  | 'pp'
  | 'twi'
  | 'dib'
  | 'eps'
  | 'elv';

export const months = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export type FilteredTitle = {
  fundTitle: string | null;
  fundId: number | null;
};

export type Subscriptions = {
  SubscriptionId: string;
  FundType: string;
  SubFundType: string;
  FundName: string;
  SubFundName: string | null;
  Frequency: string;
  TranslatedFrequency: string;
  Amount: string;
  Currency: string;
  Status: string;
  NextCharged: string;
  MerchantConfig: string;
  WidgetID: string;
  EmailAddress: string;
  PhoneNumber: string;
  SubscriptionCreatedBy: string;
  SubscriptionCreationDate: string;
  SubscriptionCreationSource: string;
  NextChargeTimestamp: string;
  SubscriptionLastModificationDate: string;
  Payment: PaymentDetails;
  Individual: IndividualDetails;
  Address: AddressDetails;
};

export type PaymentDetails = {
  PaymentType: string;
  MaskedCC?: string | null;
  ExpirationMonth?: string | null;
  ExpirationYear?: string | null;
};

export type IndividualDetails = {
  MemberToken: string;
  FirstName: string;
  LastName: string;
  LocalizedName: string;
};

export type AddressDetails = {
  AddressLine1?: string;
  AddressLine2?: string | null;
  AddressLine3?: string | null;
  City?: string | null;
  State?: string | null;
  InternationalProvince?: string | null;
  PostalCode?: string | null;
  Country?: string | null;
};

export interface Countries {
  id: string;
  iso2: string;
  name?: CountryTNames;
}

export type CountryTNames = {
  de: string;
  en: string;
  es: string;
  fr: string;
  it: string;
  ja: string;
  ko: string;
  pt: string;
  default: string;
};

export interface Currencies {
  id: string;
  code: string;
  name: CurrencyTNames;
}

export type CurrencyTNames = {
  de: string;
  en: string;
  es: string;
  fr: string;
  it: string;
  ja: string;
  ko: string;
  pt: string;
};

export interface Fund {
  id: number;
  fundId: string;
  fundSubId: string;
  isPaymentType?: boolean;
  recurring?: boolean;
  typeName?: string;
  alias?: string;
  rnwPurposeText?: string;
  fundTitle?: {
    en?: string;
    fr?: string;
    de?: string;
    es?: string;
    it?: string;
    ja?: string;
    ko?: string;
    pt?: string;
  };
  fundSubtitle?: {
    en?: string;
    fr?: string;
    de?: string;
    es?: string;
    it?: string;
    ja?: string;
    ko?: string;
    pt?: string;
  };
}

export const FundName = {
  'PPPOLIO PLUS': 1,
  'PPPP RIDE': 10,
  APFCHILD: 19,
  APFCOMMUNITY: 28,
  APFHEALTH: 37,
  APFLITERACY: 46,
  APFPEACE: 55,
  APFWATER: 64,
  'APFAPF-SHARE': 73,
  'APFWORLD FUND': 82,
  APFENVIRONMENT: 86,
  'PFPF-SHARE': 91,
  'PFPF-WF': 100,
  PFPFGRTC: 109,
  'DRDST-RESPON': 118,
  RIDJARCHIVES: 127,
  'RIDJBTH RNT IA': 136,
  'RIDJBTH RNT IC': 145,
  'RIDJCLUB DISPUTES': 154,
  'RIDJCORP CARD': 163,
  'RIDJTRF DINNERS': 172,
  'RIDJDG ALLOC': 181,
  'RIDJEMPLOYEE ADVNC': 190,
  'OTHFMEDALLION SALES': 199,
  'OTHFPEACE TIES': 208,
  'PFPF-AYAS': 255,
  PFPFDSCH: 260,
  PFPFGGBEL: 265,
  PFPFGGDPT: 270,
  PFPFGGECD: 275,
  PFPFGGPEACE: 280,
  PFPFGGWTR: 285,
  'PFPF-PCAS': 290,
  'PFPF-PCCP': 295,
  'PFPF-RWPF': 300,
  PFPFGGMATRN: 305,
  AFRFCLEARING: 301,
  'OTHFRETURN OF FUNDS': 250,
  'DST-PAKISTDST-PAKIST': 315,
  'DST-UKRAINDST-UKRAIN': 320,
  'DST-RESPON1DST-RESPON1': 325,
  'DST-RESPON2DST-RESPON2': 330,
  'DST-RESP3DST-RESP3': 335,
};
