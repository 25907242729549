import { gql } from '@apollo/client';

export const getFunds = gql`
  query getFunds {
    funds: getFunds {
      id
      fundId
      fundSubId
      isPaymentType
      recurring
      typeName
      alias
      rnwPurposeText
      fundTitle {
        en
        fr
        de
        es
        it
        ja
        ko
        pt
      }
      fundSubtitle {
        en
        fr
        de
        es
        it
        ja
        ko
        pt
      }
    }
  }
`;
