import { gql } from '@apollo/client';

export const getSubscriptionsForMember = gql`
  query getSubscriptionsForMember($MemberID: String!) {
    subscriptions: getSubscriptionsForMember(MemberID: $MemberID) {
      SubscriptionId
      FundType
      SubFundType
      FundName
      Frequency
      TranslatedFrequency
      Amount
      Currency
      Status
      NextCharged
      MerchantConfig
      WidgetID
      EmailAddress
      PhoneNumber
      SubscriptionCreatedBy
      SubscriptionCreationDate
      SubscriptionCreationSource
      NextChargeTimestamp
      SubscriptionLastModificationDate
      Payment {
        PaymentType
        MaskedCC
        ExpirationMonth
        ExpirationYear
      }
      Individual {
        MemberToken
        FirstName
        LastName
        LocalizedName
      }
      Address {
        AddressLine1
        AddressLine2
        AddressLine3
        Country
        State
        City
        PostalCode
        InternationalProvince
      }
    }
  }
`;
