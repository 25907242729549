import { useLazyQuery } from '@apollo/client';

import { getCurrencies } from '../queries';

import {
  GetCurrenciesQuery,
  GetCurrenciesQueryVariables,
} from '@typings/operations';

export const useGetCurrencies = () =>
  useLazyQuery<GetCurrenciesQuery, GetCurrenciesQueryVariables>(getCurrencies);
