import { useLazyQuery } from '@apollo/client';

import { getCountries } from '../queries';

import {
  GetCountriesQuery,
  GetCountriesQueryVariables,
} from '@typings/operations';

export const useGetCountries = () =>
  useLazyQuery<GetCountriesQuery, GetCountriesQueryVariables>(getCountries);
