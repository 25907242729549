import { gql } from '@apollo/client';

export const getCountries = gql`
  query getCountries {
    countries: getCountries {
      id
      iso2
      name {
        de
        en
        es
        fr
        it
        ja
        pt
        ko
        default
      }
    }
  }
`;
