import React from 'react';

import RequireLogin from '@components/Auth/RequireLogin';
import { OneColumn } from '@components/Layouts/OneColumn';
import NotFound from '@components/Routing/NotFound';
import MyDonations from '@presenters/web/pages/OnlineGiving/MyDonations/MyDonations';

import { FEATURE_MY_DONATIONS, isEnabled } from '@utils/features';

const MyDonationsPage: React.FC = () => {
  const isMyDonationsEnabled = isEnabled(FEATURE_MY_DONATIONS);
  if (!isMyDonationsEnabled) {
    return <NotFound />;
  }
  return (
    <RequireLogin>
      <OneColumn>
        <MyDonations />
      </OneColumn>
    </RequireLogin>
  );
};
export default MyDonationsPage;
