import React, {
  forwardRef,
  ForwardRefRenderFunction,
  PropsWithChildren,
} from 'react';

import { Helmet } from '@external/react-helmet-async';

interface TitleProps {
  className?: string;
  headTitle?: string;
}

const Title: ForwardRefRenderFunction<
  HTMLHeadingElement,
  PropsWithChildren<TitleProps>
> = ({ className, headTitle, children }, ref) => {
  const title = headTitle || children?.toString();

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <h1 className={className} ref={ref}>
        {children}
      </h1>
    </>
  );
};

Title.displayName = 'Title';

export default forwardRef(Title);
