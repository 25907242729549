import * as React from 'react';
import { useTranslation } from '@external/react-i18next';
import Title from '../../Title';
import { OneColumn } from '../../Layouts/OneColumn';
import { Button } from '../../Button';

export const ServerError: React.FC<{
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
}> = ({ children, subtitle }) => {
  const { t } = useTranslation();
  return (
    <OneColumn>
      <div className="max-w-2xl mb-20">
        <Title>{t('error-page.404.title', `We're sorry`)}</Title>
        {subtitle && <h2>{subtitle}</h2>}
        {children}
        <Button
          clickHandler={() => window.history.back()}
          className="mt-6 mr-4"
        >
          {t('error-page.404.button-try-again', `Try Again`)}
        </Button>
        <Button secondary to={'/'} className="mt-6">
          {t('error-page.404.button', `Go to Home`)}
        </Button>
      </div>
    </OneColumn>
  );
};
