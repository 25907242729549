import { useLazyQuery } from '@apollo/client';

import { getSubscriptionsForMember } from '../queries';

import {
  GetSubscriptionsForMemberQuery,
  GetSubscriptionsForMemberQueryVariables,
} from '@typings/operations';

export const useGetSubscriptionsForMember = () =>
  useLazyQuery<
    GetSubscriptionsForMemberQuery,
    GetSubscriptionsForMemberQueryVariables
  >(getSubscriptionsForMember);
