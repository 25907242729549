import * as React from 'react';

import { ServerError } from '..';

import { useTranslation } from '@external/react-i18next';

export const ErrorPage404: React.FC = () => {
  const { t } = useTranslation();
  return (
    <ServerError
      subtitle={t(
        'error-page.404.subtitle',
        'The page you requested was not found.'
      )}
    >
      <p>
        {t(
          'error-page.404.children',
          'Please try one of the following options:'
        )}
      </p>
    </ServerError>
  );
};
