import { gql } from '@apollo/client';

export const getCurrencies = gql`
  query getCurrencies {
    currencies: getCurrencies {
      id
      code
      name {
        de
        en
        es
        fr
        it
        ja
        ko
        pt
      }
    }
  }
`;
